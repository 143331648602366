import React from 'react'

function PoliticsContent() {
  return (
    <div className='mx-auto text-center'>
        <div>
        <h1 document-name="privacyPolicy" class="document-heading center">
    Polityka prywatności Sklepu Sklep z trendującymi artykułami    <br/>
    https://hkcode.pl   <br/>
    („Sklep”)
</h1>

<section>
    <h2>
        Szanowni Użytkownicy!
    </h2>

    <p>
        Troszczymy się o Twoją prywatność i pragniemy, abyś czuł się komfortowo podczas korzystania z naszych usług. Dlatego poniżej przedstawiamy najważniejsze informacje dotyczące zasad przetwarzania Twoich danych osobowych oraz plików cookies, które wykorzystujemy w naszym Sklepie. Wszystkie te działania są zgodne z Rozporządzeniem Ogólnym o Ochronie Danych Osobowych (RODO).
    </p>
</section>

<section>
    <h2>
        ADMINISTRATOR DANYCH OSOBOWYCH
    </h2>

    <p>
        Administratorem danych osobowych jest firma HECHT COMPANY
    </p>

    <p>
        Jeśli masz pytania dotyczące przetwarzania Twoich danych osobowych, prosimy o kontakt z nami pod adresem e-mail: bok@paradiseoftrendy.pl.
    </p>
</section>

<section>
    <h2>
        TWOJE PRAWA
    </h2>

    <p>
        Przysługują Ci następujące prawa:
    </p>

    <ul>
        <li>
            Prawo dostępu do Twoich danych osobowych, w tym możliwość uzyskania kopii Twoich danych (zgodnie z art. 15 RODO lub - jeśli ma to zastosowanie - z art. 13 ust. 1 lit. f RODO).
        </li>
        <li>
            Prawo do sprostowania swoich danych (zgodnie z art. 16 RODO).
        </li>
        <li>
            Prawo do usunięcia swoich danych (zgodnie z art. 17 RODO).
        </li>
        <li>
            Prawo do ograniczenia przetwarzania (zgodnie z art. 18 RODO).
        </li>
        <li>
            Prawo do przeniesienia danych do innego administratora (zgodnie z art. 20 RODO).
        </li>
    </ul>

    <p>
        Masz również prawo do:
    </p>
    <ul>
        <li>
            W każdym momencie zgłosić sprzeciw wobec przetwarzania Twoich danych:
            <ul>
                <li>
                    z powodów związanych z Twoją szczególną sytuacją – w odniesieniu do przetwarzania danych osobowych dotyczących Ciebie, opartego na art. 6 ust. 1 lit. f RODO (czyli przetwarzania w oparciu o prawnie uzasadnione interesy) (zgodnie z art. 21 ust. 1 RODO).
                </li>
                <li>
                    Jeśli Twoje dane osobowe są przetwarzane w celach marketingu bezpośredniego, masz prawo do zgłoszenia sprzeciwu w zakresie przetwarzania związanego z takim marketingiem bezpośrednim (zgodnie z art. 21 ust. 2 RODO).
                </li>
            </ul>
        </li>
    </ul>
    <p>
        W celu skorzystania z tych praw, prosimy o kontakt z nami. Sprzeciw wobec wykorzystywania plików cookies (o których przeczytasz poniżej) można również wyrazić poprzez odpowiednie ustawienia przeglądarki.
    </p>
    <p>
        Jeśli uważasz, że Twoje dane są przetwarzane niezgodnie z przepisami prawa, masz prawo złożyć skargę do Prezesa Urzędu Ochrony Danych Osobowych.
    </p>
</section>

<section>
    <h2>DANE OSOBOWE I POLITYKA PRYWATNOŚCI</h2>

    <p>Poniżej znajdziesz szczegółowe informacje dotyczące przetwarzania Twoich danych w zależności od działań podjętych przez Ciebie.</p>

    <h3>1. Złożenie zamówienia w Sklepie</h3>

    <table >
        <tr>
            <th   colspan="2">Cel przetwarzania danych</th>
        </tr>
        <tr>
            <td   colspan="2">Przetwarzanie Twoich danych jest niezbędne do zrealizowania zamówienia.</td>
        </tr>
        <tr>
            <th   colspan="2">Podstawa prawna</th>
        </tr>
        <tr>
            <td >Podstawa prawna to umowa sprzedaży (art. 6 ust. 1 lit. b RODO).</td>
            <td >Ponadto, przetwarzanie jest wymagane ze względu na obowiązki prawne związane z rachunkowością (art. 6 ust. 1 lit. c RODO).</td>
        </tr>
        <tr>
            <th   colspan="2">Okres przechowywania</th>
        </tr>
        <tr>
            <td >Twoje dane będą przechowywane przez czas obowiązywania umowy.</td>
            <td >Dodatkowo, dane będą przechowywane do czasu wygaśnięcia obowiązku prawnego związanego z rachunkowością.</td>
        </tr>
        <tr>
            <td   colspan="2">Ponadto, Twoje dane będą przetwarzane przez czas umożliwiający dochodzenie roszczeń - zarówno przez Ciebie, jak i przez nas. Więcej informacji znajdziesz w ostatniej tabeli tej sekcji.</td>
        </tr>
        <tr>
            <th   colspan="2">Co się stanie, jeśli nie podasz danych?</th>
        </tr>
        <tr>
            <td   colspan="2">Jeśli nie podasz wymaganych danych, niestety nie będziemy w stanie zrealizować Twojego zamówienia.</td>
        </tr>
    </table>
    <br/>
	
	
	
	<h3>
    2. Założenie konta w Sklepie
</h3>

<table >
    <tr>
        <th  >
            Cel przetwarzania danych
        </th>
    </tr>
    <tr>
        <td  >
            Twoje dane są przetwarzane w celu umożliwienia świadczenia usługi prowadzenia konta w Sklepie.
        </td>
    </tr>
    <tr>
        <th  >
            Podstawa prawna
        </th>
    </tr>
    <tr>
        <td  >
            Podstawą prawną przetwarzania danych jest umowa o świadczenie usług (art. 6 ust. 1 lit. b RODO).
        </td>
    </tr>
    <tr>
        <th  >
            Okres przechowywania
        </th>
    </tr>
    <tr>
        <td  >
            Twoje dane będą przechowywane przez okres obowiązywania umowy o świadczenie usług.
        </td>
    </tr>
    <tr>
        <td  >
            Ponadto, Twoje dane będą przetwarzane do momentu, w którym można dochodzić roszczeń, zarówno przez Ciebie, jak i przez nas. Dodatkowe informacje znajdziesz w ostatniej tabeli tej sekcji.
        </td>
    </tr>
    <tr>
        <th  >
            Konsekwencje braku podania danych
        </th>
    </tr>
    <tr>
        <td  >
            Brak podania danych uniemożliwi założenie konta i korzystanie z jego funkcji, takich jak przeglądanie historii zamówień czy sprawdzanie statusu zamówienia.
        </td>
    </tr>
</table>
<br/>



<h3>
    3. Nawiązanie z nami kontaktu (np. w celu zadania pytania)
</h3>

<table >
    <tr>
        <th   colspan="2">
            Cel przetwarzania danych
        </th>
    </tr>
    <tr>
        <td   colspan="2">
            Twoje dane są przetwarzane w celu obsługi Twoich zapytań lub zgłoszeń.
        </td>
    </tr>
    <tr>
        <th   colspan="2">
            Podstawa prawna
        </th>
    </tr>
    <tr>
        <td >
            Jeśli Twoje zapytanie lub zgłoszenie dotyczy umowy, której jesteśmy lub możemy być stroną, podstawą prawną jest umowa lub działania podejmowane na Twoje żądanie, zmierzające do jej zawarcia (art. 6 ust. 1 lit. b RODO).
        </td>
        <td >
            Jeśli Twoje zapytanie lub zgłoszenie nie ma związku z umową, podstawą prawną jest nasz prawnie uzasadniony interes, polegający na przetwarzaniu Twoich danych w celu prowadzenia z Tobą komunikacji (art. 6 ust. 1 lit. f RODO).
        </td>
    </tr>
    <tr>
        <th   colspan="2">
            Okres przechowywania
        </th>
    </tr>
    <tr>
        <td >
            Twoje dane będą przechowywane przez czas trwania wiążącej nas umowy lub - jeśli umowa nie zostanie zawarta - do upływu okresu dochodzenia roszczeń. Zobacz ostatnią tabelę tej sekcji.
        </td>
        <td >
            Twoje dane będą przechowywane do upływu okresu dochodzenia roszczeń lub do momentu, w którym uwzględnimy Twój sprzeciw wobec przetwarzania. Zobacz ostatnią tabelę tej sekcji.
        </td>
    </tr>
    <tr>
        <td   colspan="2">
            Ponadto, Twoje dane będą przetwarzane do upływu okresu, w którym możliwe jest dochodzenie roszczeń - zarówno przez Ciebie, jak i przez nas. Więcej informacji znajdziesz w ostatniej tabeli tej sekcji.
        </td>
    </tr>
    <tr>
        <th   colspan="2">
            Konsekwencje braku podania danych
        </th>
    </tr>
    <tr>
        <td   colspan="2">
            Brak podania danych uniemożliwi nam udzielenie odpowiedzi na Twoje zapytanie lub zgłoszenie.
        </td>
    </tr>
</table>
<br/>
* W zależności od tego, które ma zastosowanie w danym przypadku.
<br/>
<br/>



<h3>
    4. Wyrażenie przez Ciebie zgody na otrzymywanie od nas treści marketingowych (np. informacji o ofertach specjalnych)
</h3>

<table >
    <tr>
        <th   colspan="2">
            Cel przetwarzania danych
        </th>
    </tr>
    <tr>
        <td >
            Twoje dane są przetwarzane w celu wysyłki informacji marketingowych, zwłaszcza ofert specjalnych.
        </td>
        <td >
            Przetwarzamy także Twoje dane w celu analizy efektywności wysyłanych przez nas wiadomości, co pomaga nam ustalić ogólne zasady dotyczące skutecznej wysyłki wiadomości w naszej działalności. Więcej na ten temat przeczytasz w sekcji „Działania analityczne” Polityki prywatności.
        </td>
    </tr>
    <tr>
        <th   colspan="2">
            Podstawa prawna
        </th>
    </tr>
    <tr>
        <td >
            Podstawą prawną przetwarzania Twoich danych w tym celu jest Twoja zgoda na nasze działania marketingowe (art. 6 ust. 1 lit. a RODO).
        </td>
        <td >
            Mamy także prawnie uzasadniony interes, polegający na przetwarzaniu danych w celu prowadzenia analizy efektywności naszych działań marketingowych (art. 6 ust. 1 lit. f RODO), zwłaszcza jeśli Twoje zapytanie lub zgłoszenie nie jest związane z umową.
        </td>
    </tr>
    <tr>
        <th   colspan="2">
            Okres przechowywania
        </th>
    </tr>
    <tr>
        <td >
            Twoje dane będą przechowywane do momentu wycofania przez Ciebie zgody – pamiętaj, w każdej chwili możesz wycofać zgodę. Przetwarzanie danych do momentu cofnięcia zgody pozostaje zgodne z prawem.
        </td>
        <td >
            Przechowujemy Twoje dane do momentu, w którym uwzględnimy Twój sprzeciw wobec przetwarzania.
        </td>
    </tr>
    <tr>
        <td   colspan="2">
            Ponadto, Twoje dane będą przetwarzane do upływu okresu, w którym możliwe jest dochodzenie roszczeń - zarówno przez Ciebie, jak i przez nas. Więcej informacji znajdziesz w ostatniej tabeli tej sekcji.
        </td>
    </tr>
    <tr>
        <th   colspan="2">
            Konsekwencje braku podania danych
        </th>
    </tr>
    <tr>
        <td   colspan="2">
            Jeśli nie podasz danych, nie będziesz otrzymywać naszych materiałów marketingowych, w tym informacji o naszych ofertach specjalnych.
        </td>
    </tr>
</table>
<br/>



<h3>
    5. Zapisanie się na newsletter
</h3>

<table >
    <tr>
        <th   colspan="2">
            Cel przetwarzania danych
        </th>
    </tr>
    <tr>
        <td >
            Twoje dane są przetwarzane w celu wysyłki newslettera zawierającego informacje o naszych produktach i usługach.
        </td>
        <td >
            Przetwarzamy także Twoje dane w celu analizy efektywności wysyłanych przez nas treści, co pomaga nam ustalić ogólne zasady dotyczące skutecznej wysyłki wiadomości w naszej działalności. Więcej na ten temat przeczytasz w sekcji „Działania analityczne” Polityki prywatności.
        </td>
    </tr>
    <tr>
        <th   colspan="2">
            Podstawa prawna
        </th>
    </tr>
    <tr>
        <td >
            Podstawą prawną przetwarzania Twoich danych w celu wysyłki newslettera jest umowa o świadczenie usługi wysyłki newslettera (art. 6 ust. 1 lit. b RODO).
        </td>
        <td >
            Mamy także prawnie uzasadniony interes, polegający na przetwarzaniu danych w celu analizy efektywności naszych działań (art. 6 ust. 1 lit. f RODO), zwłaszcza jeśli Twoje zapytanie lub zgłoszenie nie jest związane z umową.
        </td>
    </tr>
    <tr>
        <th   colspan="2">
            Okres przechowywania
        </th>
    </tr>
    <tr>
        <td >
            Twoje dane będą przechowywane do momentu, w którym wypiszesz się z naszego newslettera.
        </td>
        <td >
            Przechowujemy Twoje dane do momentu, w którym uwzględnimy Twój sprzeciw wobec przetwarzania.
        </td>
    </tr>
    <tr>
        <td   colspan="2">
            Ponadto, Twoje dane będą przetwarzane do upływu okresu, w którym możliwe jest dochodzenie roszczeń - zarówno przez Ciebie, jak i przez nas. Więcej informacji znajdziesz w ostatniej tabeli tej sekcji.
        </td>
    </tr>
    <tr>
        <th   colspan="2">
            Konsekwencje braku podania danych
        </th>
    </tr>
    <tr>
        <td   colspan="2">
            Jeśli nie podasz danych, nie będziesz mieć możliwości otrzymywania informacji dotyczących Sklepu i naszych usług poprzez newsletter.
        </td>
    </tr>
</table>
<br/>



<h3>
    6. Podjęcie działania lub zaniechanie mogące powodować powstanie roszczeń związanych ze Sklepem lub naszymi usługami
</h3>

<table >
    <tr>
        <th  >
            Cel przetwarzania danych
        </th>
    </tr>
    <tr>
        <td  >
            Przetwarzamy Twoje dane w celu ustalenia, dochodzenia lub obrony ewentualnych roszczeń związanych z zawartą umową lub świadczonymi usługami.
        </td>
    </tr>
    <tr>
        <th  >
            Podstawa prawna
        </th>
    </tr>
    <tr>
        <td  >
            Podstawą prawną przetwarzania Twoich danych w tym celu jest nasz prawnie uzasadniony interes polegający na przetwarzaniu danych osobowych (art. 6 ust. 1 lit. f RODO).
        </td>
    </tr>
    <tr>
        <th  >
            Okres przechowywania
        </th>
    </tr>
    <tr>
        <td  >
            Twoje dane będą przechowywane do upływu okresu przedawnienia roszczeń lub do momentu, w którym uwzględnimy Twój sprzeciw wobec przetwarzania.
        </td>
    </tr>
    <tr>
        <th  >
            Konsekwencje braku podania danych
        </th>
    </tr>
    <tr>
        <td  >
            Jeśli nie podasz danych, nie będziemy mieli możliwości ustalenia, dochodzenia lub obrony roszczeń związanych ze Sklepem lub naszymi usługami.
        </td>
    </tr>
</table>
<br/>
<br/>
</section>

<section>
            <h2>
                DZIAŁANIA ANALITYCZNE
            </h2>
            <p>
                Jeśli wyrazisz zgodę na otrzymywanie od nas wiadomości marketingowych lub newslettera, będziemy przeprowadzać analizę skuteczności naszych przesyłek. Na przykład, zbadamy, czy oraz w jaki sposób te wiadomości wpłynęły na aktywność w naszym sklepie. Te działania pomogą nam określić ogólne wytyczne dotyczące wysyłki takich komunikatów w naszej działalności, takie jak najlepszy czas wysyłki czy strategie tworzenia efektywnych treści.
            </p>
        </section>
    <section>
        <h2>
            BEZPIECZEŃSTWO DANYCH
        </h2>
        <p>
           W procesie przetwarzania Twoich danych osobowych wdrażamy odpowiednie środki organizacyjne i techniczne zgodnie z obowiązującymi przepisami prawa, co obejmuje zastosowanie szyfrowania połączenia przy wykorzystaniu certyfikatu SSL/TLS.
        </p>
    </section>
	
	<section>
    <h2>
        PLIKI COOKIES
    </h2>
<p>
    Nasz Sklep, podobnie jak wiele innych witryn internetowych, wykorzystuje tzw. pliki cookies (ciasteczka). Pliki te:
</p>
<ul>
    <li>
        są zapisywane w pamięci Twojego urządzenia (np. komputera lub telefonu);
    </li>
    <li>
        nie wpływają na ustawienia Twojego urządzenia.
    </li>
</ul>

<p>
    W naszym Sklepie pliki cookies są używane w celu:
</p>
<ul>
    <li>
        zapamiętania Twojej sesji,
    </li>
    <li>
        prowadzenia statystyk.
    </li>
</ul>

<p>
    Jeśli chciałbyś dowiedzieć się, jak zarządzać plikami cookies, w tym jak wyłączyć ich obsługę w Twojej przeglądarce, możesz skorzystać z pliku pomocy Twojej przeglądarki. Informacje na ten temat znajdziesz, wciskając klawisz F1 w przeglądarce lub na następujących stronach, zależnie od używanej przeglądarki:
</p>
<ul>
    <li>
        <a href="https://support.google.com/chrome/answer/95647?hl=pl" target="_blank" rel="noopener">Google Chrome</a>
    </li>
    <li>
        <a href="https://help.opera.com/pl/latest/web-preferences/#cookies" target="_blank" rel="noopener">Opera</a>
    </li>
    <li>
        <a href="https://support.apple.com/pl-pl/guide/safari/sfri11471/mac" target="_blank" rel="noopener">Safari</a>
    </li>
    <li>
        <a href="https://support.mozilla.org/pl/kb/elementy-sledzace-zewnetrznych-witryn" target="_blank" rel="noopener">Mozilla Firefox</a>
    </li>
    <li>
        <a href="https://support.microsoft.com/pl-pl/help/4468242/microsoft-edge-browsing-data-and-privacy" target="_blank" rel="noopener">Microsoft Edge</a>
    </li>
</ul>

<p>
    Pliki cookies nie będą przetwarzane przez nas dłużej niż 30 minut od Twojej ostatniej wizyty w Sklepie.
</p>

<p>
    Korzystając z odpowiednich opcji Twojej przeglądarki, masz możliwość:
</p>
<ul>
    <li>
        usunięcia plików cookies,
    </li>
    <li>
        zablokowania wykorzystywania plików cookies w przyszłości.
    </li>
</ul>
<p>
    W takich przypadkach nie będziemy już przetwarzać tych plików.
</p>
</section>
<section>
    <h2>
        USŁUGI ZEWNĘTRZNE / ODBIORCY DANYCH
    </h2>
    <p>
        W trakcie prowadzenia naszej działalności korzystamy z usług firm zewnętrznych, które pomagają nam w jej realizacji. Przekazujemy im Twoje dane, jednak tylko na podstawie naszych udokumentowanych poleceń.
    </p>
    <p>
        Poniżej znajdziesz listę odbiorców Twoich danych:
    </p>
    <table >
        <tr>
            <th  >
                DZIAŁANIE
            </th>
            <th  >
                ODBIORCY DANYCH
            </th>
            <th  >
                PRZEKAZANIE DANYCH POZA UNIĘ EUROPEJSKĄ
            </th>
        </tr>
        <tr>
            <th   rowspan="2">
                KAŻDE DZIAŁANIE W ZWIĄZKU ZE SKLEPEM
            </th>
            <td  >
                podmiot zapewniający wsparcie techniczne/IT
            </td>
            <td  >
                nie ma miejsca
            </td>
        </tr>
        <tr>
            <td  >
                osoby współpracujące z nami na podstawie umów cywilnoprawnych, wspierające naszą bieżącą działalność
            </td>
            <td  >
                nie ma miejsca
            </td>
        </tr>
        <tr>
            <th   rowspan="1">
                PRZEBYWANIE NA STRONIE SKLEPU Z USTAWIENIAMI ZEZWALAJĄCYMI NA PROWADZENIE DZIAŁAŃ MARKETINGOWYCH
            </th>
            <td  >
                podmiot zapewniający usługi marketingowe
            </td>
            <td  >
                nie ma miejsca
            </td>
        </tr>
        <tr>
            <th   rowspan="3">
                ZŁOŻENIE ZAMÓWIENIA W SKLEPIE
            </th>
            <td  >
                dostawca płatności
            </td>
            <td  >
                nie ma miejsca
            </td>
        </tr>
        <tr>
            <td>
                podmiot dostarczający do Ciebie produkt
            </td>
            <td>
                nie ma miejsca
            </td>
        </tr>
        <tr>
            <td>
                hurtownia
            </td>
            <td>
                nie ma miejsca
            </td>
        </tr>
    </table>
    <br/>
    <p>
        Ponadto, w określonych przypadkach możemy być zobowiązani do udostępnienia Twoich danych odpowiednim organom publicznym.
    </p>
</section>
        </div>
    </div>
  )
}

export default PoliticsContent