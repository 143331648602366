import React from 'react'
import Navbar from '../compontents/Navbar'
import Footer from '../compontents/Footer'
import PoliticsContent from '../compontents/PoliticsContent'

function Polityka() {
  return (
    <div>
        
        <Navbar />
        <PoliticsContent />
        <Footer />

    </div>
  )
}

export default Polityka